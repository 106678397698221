@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);


/*# sourceMappingURL=index.css.map */

/* Define variables for responsive breakpoints */
.complex-page {
  /* Global styles */
  font-family: 'Roboto', sans-serif;
  /* Media queries for responsive design */
  /* Media queries for responsive design */ }
  .complex-page .billboard-section {
    /* Billboard styles */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #9ce71a, #4c5444);
    color: #fff;
    padding: 2rem; }
    .complex-page .billboard-section .billboard-content {
      text-align: center;
      max-width: 800px;
      margin-bottom: 2rem; }
      .complex-page .billboard-section .billboard-content .title {
        font-size: 3.5rem;
        font-weight: bold;
        margin-bottom: 1rem; }
      .complex-page .billboard-section .billboard-content .subtitle {
        font-size: 1.5rem;
        font-weight: 300; }
      .complex-page .billboard-section .billboard-content .cta-button {
        font-size: 1.2rem;
        font-weight: bold;
        border-radius: 999px;
        padding: 0.75rem 2rem;
        text-transform: uppercase;
        background-color: #72955a;
        color: #fff;
        border: none;
        cursor: pointer;
        transition: background-color 0.2s ease; }
        .complex-page .billboard-section .billboard-content .cta-button:hover {
          background-color: #72955a; }
    .complex-page .billboard-section .mobile-download-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.5rem; }
      .complex-page .billboard-section .mobile-download-buttons a {
        display: inline-flex;
        justify-content: center;
        align-items: center; }
        .complex-page .billboard-section .mobile-download-buttons a .mobile-download-icon {
          color: #9ce71a; }
  .complex-page .job-posts-section {
    background-color: #f9f9f9;
    padding: 4rem 0;
    /* Add your styles for the job post list here (e.g., card styles) */
    /* ... */ }
    .complex-page .job-posts-section .section-title {
      display: flex;
      font-size: 2.5rem;
      margin: 2rem auto;
      text-align: center;
      justify-content: center; }
    .complex-page .job-posts-section .filter-options {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      grid-gap: 1rem;
      gap: 1rem;
      margin-top: 2rem; }
      .complex-page .job-posts-section .filter-options .search-field {
        width: 100%;
        max-width: 300px; }
      .complex-page .job-posts-section .filter-options .job-type-select {
        min-width: 150px; }
      .complex-page .job-posts-section .filter-options .complexity-checkbox {
        display: flex;
        align-items: center; }
        .complex-page .job-posts-section .filter-options .complexity-checkbox .MuiFormControlLabel-label {
          margin-right: 0; }
    .complex-page .job-posts-section .table-render {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5%;
      width: 90%;
      overflow-x: scroll; }
  @media (max-width: 768px) {
    .complex-page .section-title {
      font-size: 2rem; } }
  @media (max-width: 576px) {
    .complex-page .section-title {
      font-size: 1.8rem; }
    .complex-page .filter-options {
      flex-direction: column;
      align-items: flex-start; }
      .complex-page .filter-options .search-field {
        width: 100%;
        max-width: none; }
      .complex-page .filter-options .job-type-select {
        width: 100%;
        max-width: none; } }
  .complex-page section {
    /* Common styles for sections */
    padding: 2rem 0 2rem 0;
    /* Increased padding between sections */ }
  .complex-page .about-us-section {
    padding-top: 1rem;
    /* About Us section styles */
    background-color: #f9f9f9; }
    .complex-page .about-us-section .section-title {
      font-size: 2rem;
      font-weight: bold;
      color: #343533;
      margin: auto;
      text-align: center;
      justify-content: center; }
    .complex-page .about-us-section .section-description {
      font-size: 1.2rem;
      font-weight: 300;
      padding: 2rem auto;
      text-align: center;
      justify-content: center; }
    .complex-page .about-us-section .about-us-content {
      max-width: 800px;
      margin: 0 auto;
      text-align: center; }
    .complex-page .about-us-section .about-us-image {
      max-width: 100%;
      height: auto;
      margin-top: 2rem; }
  .complex-page .our-services-section {
    /* Our Services section styles */
    background-color: #fff; }
    .complex-page .our-services-section .section-title {
      font-size: 2rem;
      font-weight: bold;
      color: #343533;
      margin: auto;
      text-align: center;
      justify-content: center; }
    .complex-page .our-services-section .section-description {
      font-size: 1.2rem;
      font-weight: 300;
      padding: 2rem auto;
      text-align: center;
      justify-content: center; }
    .complex-page .our-services-section .service-list {
      list-style-type: none;
      padding: 0;
      margin-top: 1.5rem;
      text-align: center; }
      .complex-page .our-services-section .service-list li {
        font-size: 1.2rem;
        color: #333;
        margin-bottom: 1rem;
        position: relative;
        padding-left: 1.5rem; }
        .complex-page .our-services-section .service-list li:before {
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          position: absolute;
          left: 0;
          top: 50%;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
          color: #73b304;
          font-size: 1.3rem; }
  .complex-page .our-clients-section {
    /* Our Clients section styles */
    background-color: #f9f9f9; }
    .complex-page .our-clients-section .section-title {
      font-size: 2rem;
      font-weight: bold;
      color: #343533;
      margin: auto;
      text-align: center;
      justify-content: center; }
    .complex-page .our-clients-section .section-description {
      font-size: 1.2rem;
      font-weight: 300;
      padding: 2rem auto;
      text-align: center;
      justify-content: center; }
    .complex-page .our-clients-section .client-logos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-top: 2rem; }
      .complex-page .our-clients-section .client-logos .client-logo {
        max-width: 120px;
        height: auto;
        margin: 1rem;
        opacity: 0.8; }
  .complex-page .testimonials-section {
    /* Testimonials section styles */
    background-color: #fff; }
    .complex-page .testimonials-section .section-title {
      font-size: 2rem;
      font-weight: bold;
      color: #343533;
      margin: auto;
      text-align: center;
      justify-content: center; }
    .complex-page .testimonials-section .section-description {
      font-size: 1.2rem;
      font-weight: 300;
      padding: 2rem auto;
      text-align: center;
      justify-content: center; }
    .complex-page .testimonials-section .testimonial {
      max-width: 600px;
      margin: 0 auto;
      padding: 1.5rem;
      background-color: #f7f7f7;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 2rem;
      text-align: center; }
      .complex-page .testimonials-section .testimonial p {
        font-size: 1.2rem;
        font-weight: 300;
        margin-bottom: 1rem;
        color: #555; }
      .complex-page .testimonials-section .testimonial .testimonial-author {
        font-size: 1rem;
        font-weight: bold;
        color: #9ce71a; }
  .complex-page .contact-section {
    /* Contact Us section styles */
    background-color: #f9f9f9; }
    .complex-page .contact-section .section-title {
      font-size: 2rem;
      font-weight: bold;
      color: #343533;
      margin: auto;
      text-align: center;
      justify-content: center; }
    .complex-page .contact-section .section-description {
      font-size: 1.2rem;
      font-weight: 300;
      padding: 2rem auto;
      text-align: center;
      justify-content: center; }
    .complex-page .contact-section .contact-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 600px;
      margin: 0 auto; }
      .complex-page .contact-section .contact-form .form-field {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
        width: 100%; }
        .complex-page .contact-section .contact-form .form-field label {
          font-size: 1.2rem;
          margin-bottom: 0.5rem;
          color: #555; }
        .complex-page .contact-section .contact-form .form-field input,
        .complex-page .contact-section .contact-form .form-field textarea {
          padding: 0.75rem 1rem;
          font-size: 1rem;
          border: 1px solid #ccc;
          border-radius: 5px;
          width: 100%;
          color: #333; }
          .complex-page .contact-section .contact-form .form-field input:focus,
          .complex-page .contact-section .contact-form .form-field textarea:focus {
            outline: none;
            border-color: #9ce71a; }
        .complex-page .contact-section .contact-form .form-field textarea {
          resize: vertical;
          min-height: 120px; }
      .complex-page .contact-section .contact-form button[type='submit'] {
        font-size: 1.2rem;
        font-weight: bold;
        border-radius: 999px;
        padding: 0.75rem 2rem;
        text-transform: uppercase;
        background-color: #9ce71a;
        color: #fff;
        border: none;
        cursor: pointer;
        transition: background-color 0.2s ease; }
        .complex-page .contact-section .contact-form button[type='submit']:hover {
          background-color: #088f39; }
  .complex-page .footer {
    /* Footer styles */
    background-color: #9ce71a;
    color: #fff;
    padding: 1rem 0;
    text-align: center;
    font-size: 1rem; }
    .complex-page .footer .footer-text {
      font-size: 1rem;
      color: #fff; }
  @media (max-width: 768px) {
    .complex-page {
      /* Billboard section */ }
      .complex-page .billboard-content {
        max-width: 90%; }
        .complex-page .billboard-content .mobile-download-buttons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; }
          .complex-page .billboard-content .mobile-download-buttons .mobile-download-icon {
            padding: auto .5rem; }
      .complex-page .hero-image {
        max-width: 90%; } }
  @media (max-width: 576px) {
    .complex-page {
      /* Billboard section */
      /* About Us section */
      /* Our Services section */
      /* Our Clients section */
      /* Testimonials section */
      /* Contact Us section */ }
      .complex-page .billboard-content .title {
        font-size: 2.5rem; }
      .complex-page .billboard-content .subtitle {
        font-size: 1.2rem; }
      .complex-page .billboard-content .cta-button {
        font-size: 1rem; }
      .complex-page .billboard-content .mobile-download-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        .complex-page .billboard-content .mobile-download-buttons .mobile-download-icon {
          padding: auto .5rem; }
      .complex-page .about-us-content .title {
        font-size: 2rem; }
      .complex-page .about-us-content .section-description {
        font-size: 1rem; }
      .complex-page .service-list li {
        font-size: 1rem; }
      .complex-page .client-logos .client-logo {
        max-width: 75px; }
      .complex-page .testimonial {
        padding: 1rem; }
        .complex-page .testimonial p {
          font-size: 1rem; }
        .complex-page .testimonial .testimonial-author {
          font-size: 0.9rem; }
      .complex-page .contact-form {
        max-width: 100%; }
        .complex-page .contact-form .form-field input,
        .complex-page .contact-form .form-field textarea {
          font-size: 1rem; }
        .complex-page .contact-form button[type='submit'] {
          font-size: 1rem; } }

/*# sourceMappingURL=App.css.map */

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif; }

#NavIcon {
  width: 32px;
  height: 32px;
  cursor: pointer; }

.app-bar {
  background-color: #007BFF; }

.app-title {
  flex-grow: 1; }

.container {
  margin-top: 1rem; }

.sort-by-dropdown, .within-dropdown {
  margin: 0.5rem;
  min-width: 120px; }

.search-button {
  margin-top: 0.5rem; }

.job-card {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 1.5rem;
  margin-bottom: 1.5rem; }

.job-card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem; }

.job-time {
  display: flex;
  align-items: center; }

.clock-icon {
  margin-right: 0.5rem; }

.time-date {
  font-size: 12px; }

.job-profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem; }

.profile-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1rem; }

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.profile-content {
  margin-bottom: 0.5rem; }

.star-rating {
  display: inline-block;
  vertical-align: middle; }

.stars {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  position: relative; }

.stars span {
  display: block;
  width: 0;
  height: 100%;
  transition: width 0.3s;
  background-color: #FFD700; }

.job-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem; }

.fixedwidth-button {
  width: 120px;
  text-align: center; }

.job-footer-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s; }

.load-more-button {
  text-align: center;
  margin-top: 1rem; }

.not-found {
  text-align: center;
  color: #999;
  font-size: 18px;
  padding: 1rem; }

/*# sourceMappingURL=job-find.css.map */

.W9UH3rEjIOXDQTbDbjb_x {
  box-shadow: 0 1px 2px 0 #afafc5;
  width: 50%;
  max-width: 400px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 20px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 400px;
  width: 500px; }
  .W9UH3rEjIOXDQTbDbjb_x > .title {
    text-align: center;
    font-family: "Open Sans", sans-serif; }

/*# sourceMappingURL=FloatBox.css.map */

.rdcbpxiOKMj6QD26bnVaP {
  width: 95%;
  height: 125px;
  display: flex;
  margin: 1em auto;
  align-items: center;
  background-color: #edf0f2; }
  .rdcbpxiOKMj6QD26bnVaP > div {
    padding: 10px;
    padding-top: 34px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .rdcbpxiOKMj6QD26bnVaP > div.main {
      margin: auto 75px;
      flex: 1 1;
      border-right: 1px solid #dfe1e2; }
      .rdcbpxiOKMj6QD26bnVaP > div.main > div:not(.last-child) {
        margin-right: 10px; }
    .rdcbpxiOKMj6QD26bnVaP > div.action {
      text-align: center;
      margin: auto 50px; }
  @media screen and (max-width: 768px) {
    .rdcbpxiOKMj6QD26bnVaP {
      width: 35vh;
      height: 150px;
      display: flex;
      flex-shrink: initial;
      flex-wrap: nowrap;
      flex-direction: row;
      overflow-x: scroll;
      margin: 1em auto;
      align-items: center;
      background-color: #edf0f2; }
      .rdcbpxiOKMj6QD26bnVaP > div {
        padding: 10px;
        padding-top: 34px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .rdcbpxiOKMj6QD26bnVaP > div.main {
          margin: 0;
          flex: 1 1;
          border-right: none; }
          .rdcbpxiOKMj6QD26bnVaP > div.main > div:not(.last-child) {
            margin-right: 10px; }
        .rdcbpxiOKMj6QD26bnVaP > div.action {
          text-align: center;
          margin: auto; } }

/*# sourceMappingURL=ActionBox.css.map */



/*# sourceMappingURL=Tr.css.map */

._29iBGF68C0TNFpN5YMuWA0 {
  color: #ff7d38;
  text-decoration: none; }
  ._29iBGF68C0TNFpN5YMuWA0:hover {
    -webkit-text-decoration-color: #ff9900;
            text-decoration-color: #ff9900; }
  ._29iBGF68C0TNFpN5YMuWA0:visited {
    color: #ff7d38; }

._3odGbipR1bQZqHdnqrojTI {
  color: #ff7d38;
  text-decoration: none; }
  ._3odGbipR1bQZqHdnqrojTI:visited {
    color: #ff7d38; }

/*# sourceMappingURL=Link.css.map */

._3lBb3DeCZOtB5RWYjI33wU {
  margin: 0;
  padding: 10px;
  background-color: #fcecea;
  font-weight: normal;
  color: #444;
  border-radius: 5px; }
  ._3lBb3DeCZOtB5RWYjI33wU > span.fa {
    color: red;
    margin-right: 10px;
    font-size: 20px; }

/*# sourceMappingURL=AlertBox.css.map */

a:hover {
  color: #fff; }

._2pQRNh2Je3rnMZAFbqsz-h {
  font-family: "Roboto", sans-serif;
  text-align: center;
  border: none;
  border-radius: 3px;
  color: #fff;
  background-color: #3bcf5d;
  padding: 12px 18px;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  transition: all 150ms; }
  ._2pQRNh2Je3rnMZAFbqsz-h.search {
    display: inline;
    text-align: center;
    color: #ff7d38;
    background-color: #fff;
    min-width: inherit;
    height: 36px;
    border: 1px solid #dfe1e2; }
  @media only screen and (max-width: 600px) {
    ._2pQRNh2Je3rnMZAFbqsz-h {
      padding: 12px 6px; } }
  ._2pQRNh2Je3rnMZAFbqsz-h:hover {
    box-shadow: 1px 1px 8px #888888; }
  ._2pQRNh2Je3rnMZAFbqsz-h.wide {
    width: 100%;
    border-radius: 0px; }
  ._2pQRNh2Je3rnMZAFbqsz-h.large {
    padding: 14px 24px; }
    @media only screen and (max-width: 600px) {
      ._2pQRNh2Je3rnMZAFbqsz-h.large {
        padding: 14px 12px; } }
  ._2pQRNh2Je3rnMZAFbqsz-h.times {
    width: 100%;
    margin: 0; }
  ._2pQRNh2Je3rnMZAFbqsz-h.verify {
    background-color: #413a3a;
    padding: 0px 1rem;
    font-size: x-small; }
  ._2pQRNh2Je3rnMZAFbqsz-h.rx {
    background: lightgrey;
    color: #888888;
    font-size: 1em; }
  ._2pQRNh2Je3rnMZAFbqsz-h.cancel {
    color: #888;
    background: #fff;
    border: 1px solid #eee;
    width: 100px; }
  ._2pQRNh2Je3rnMZAFbqsz-h.search {
    color: #ff7d38;
    background-color: #fff;
    min-width: inherit;
    height: 36px;
    border: 1px solid #dfe1e2; }
  ._2pQRNh2Je3rnMZAFbqsz-h.alt {
    color: #fff;
    background-color: #0392f5;
    min-width: inherit;
    border: 1px solid #dfe1e2;
    border-radius: 3px;
    text-align: center;
    margin: 5% 0; }
  ._2pQRNh2Je3rnMZAFbqsz-h.row {
    margin: auto 10px; }
  ._2pQRNh2Je3rnMZAFbqsz-h.module {
    width: 15rem;
    height: 5rem;
    min-width: 20%;
    background-color: #413a3a;
    font-family: 'Alegreya', serif;
    font-weight: 600;
    font-size: 2rem;
    margin: 10px auto;
    border-radius: 15%; }
    ._2pQRNh2Je3rnMZAFbqsz-h.module:hover {
      box-shadow: 0 0 4px 8px #e9e9e9, 0 0 4px 10px #caebf3, inset 0 0 4px 2px #d9dbdb; }
    @media only screen and (max-width: 600px) {
      ._2pQRNh2Je3rnMZAFbqsz-h.module {
        width: 50%;
        height: 15rem;
        background-color: #413a3a;
        font-family: 'Alegreya', serif;
        font-weight: 600;
        font-size: 1.5rem;
        margin: 20px auto; } }
  ._2pQRNh2Je3rnMZAFbqsz-h.danger {
    background-color: #ef4836; }
  ._2pQRNh2Je3rnMZAFbqsz-h.confirm {
    background-color: #0392f5; }
  ._2pQRNh2Je3rnMZAFbqsz-h.circle {
    border-radius: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #52bde7; }
  ._2pQRNh2Je3rnMZAFbqsz-h.inactive {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5; }
    ._2pQRNh2Je3rnMZAFbqsz-h.inactive:hover {
      box-shadow: none; }
    ._2pQRNh2Je3rnMZAFbqsz-h.inactive.verify {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 0px 1rem;
      display: inline-flex; }
  ._2pQRNh2Je3rnMZAFbqsz-h > .fa {
    margin-right: 10px; }

._1WjRMrc5vxeZ2cP7SAU91U {
  cursor: pointer;
  transition: all 150ms; }
  ._1WjRMrc5vxeZ2cP7SAU91U:hover {
    text-shadow: 1px 1px 8px #b4b3b3; }
  ._1WjRMrc5vxeZ2cP7SAU91U.danger {
    color: #ef4836; }
  ._1WjRMrc5vxeZ2cP7SAU91U.confirm {
    color: #0392f5; }

/*# sourceMappingURL=Button.css.map */

._3X640iBpLfqZlCrfhJ2GKx {
  position: absolute;
  top: 0%;
  right: 8.2%;
  font-family: "Roboto", sans-serif;
  text-align: center;
  border: none;
  border-radius: 3px;
  color: #fff;
  background-color: rgba(235, 30, 30, 0.75);
  box-shadow: inset 0px 50px 0px -20px rgba(30, 235, 43, 0.6);
  padding: 15px 20px;
  font-size: 14px;
  max-width: 50px;
  text-decoration: none;
  cursor: pointer;
  transition: all 150ms; }
  @media only screen and (max-width: 600px) {
    ._3X640iBpLfqZlCrfhJ2GKx {
      padding: 12px 6px; } }
  ._3X640iBpLfqZlCrfhJ2GKx:hover {
    background-color: rgba(24, 189, 35, 0.6);
    box-shadow: inset 0px 50px 0px -20px rgba(235, 30, 30, 0.75); }
  ._3X640iBpLfqZlCrfhJ2GKx.wide {
    width: 100%;
    border-radius: 0px; }
  ._3X640iBpLfqZlCrfhJ2GKx.large {
    padding: 14px 24px; }
    @media only screen and (max-width: 600px) {
      ._3X640iBpLfqZlCrfhJ2GKx.large {
        padding: 14px 12px; } }
  ._3X640iBpLfqZlCrfhJ2GKx.rx {
    background: lightgrey;
    color: #888888;
    font-size: 1em; }
  ._3X640iBpLfqZlCrfhJ2GKx.cancel {
    color: #888;
    background: #fff;
    border: 1px solid #eee;
    width: 100px; }
  ._3X640iBpLfqZlCrfhJ2GKx.search {
    color: #ff7d38;
    background-color: #fff;
    min-width: inherit;
    height: 36px;
    border: 1px solid #dfe1e2; }
  ._3X640iBpLfqZlCrfhJ2GKx.danger {
    background-color: #ef4836; }
  ._3X640iBpLfqZlCrfhJ2GKx.confirm {
    background-color: #0392f5; }
  ._3X640iBpLfqZlCrfhJ2GKx.inactive {
    width: auto;
    cursor: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5; }
    ._3X640iBpLfqZlCrfhJ2GKx.inactive:hover {
      box-shadow: none; }
  ._3X640iBpLfqZlCrfhJ2GKx > .fa {
    margin-right: 10px; }

._2h6_RXVkN0giWR8BnZaYP6 {
  cursor: pointer;
  transition: all 150ms; }
  ._2h6_RXVkN0giWR8BnZaYP6:hover {
    text-shadow: 1px 1px 8px #b4b3b3; }
  ._2h6_RXVkN0giWR8BnZaYP6.danger {
    color: #ef4836; }
  ._2h6_RXVkN0giWR8BnZaYP6.confirm {
    color: #0392f5; }

/*# sourceMappingURL=Button2.css.map */

._3ue5niqb3eYXiIh0yLm-FP {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px; }
  @media only screen and (max-width: 600px) {
    ._3ue5niqb3eYXiIh0yLm-FP {
      padding: 0px; } }
  ._3ue5niqb3eYXiIh0yLm-FP > h1 {
    font-weight: normal;
    text-align: center;
    flex: 1 1; }
  ._3ue5niqb3eYXiIh0yLm-FP > h2 {
    font-weight: normal; }
  ._3ue5niqb3eYXiIh0yLm-FP > h3 {
    font-weight: normal;
    text-align: center;
    flex: 1 1; }
  ._3ue5niqb3eYXiIh0yLm-FP > .icon-header {
    display: flex;
    align-items: center; }
    ._3ue5niqb3eYXiIh0yLm-FP > .icon-header > h3 {
      padding-left: 8px; }
      ._3ue5niqb3eYXiIh0yLm-FP > .icon-header > h3.back {
        color: #0392f5;
        cursor: pointer; }
  ._3ue5niqb3eYXiIh0yLm-FP > .buttons > .edit, ._3ue5niqb3eYXiIh0yLm-FP > .buttons > .delete {
    cursor: pointer;
    width: 24px; }
  ._3ue5niqb3eYXiIh0yLm-FP > .buttons > .edit {
    color: #0392f5; }
  ._3ue5niqb3eYXiIh0yLm-FP > .buttons > .delete {
    color: #ef4836; }

/*# sourceMappingURL=Header.css.map */

._3UbM065ICI_4mCzfMjfMRU {
  box-shadow: 0 1px 2px 0 #afafc5;
  width: 90%;
  background-color: #fafbfc;
  padding: 10px;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 20px; }
  ._3UbM065ICI_4mCzfMjfMRU > .form {
    max-width: 400px;
    margin: 20px auto; }
    ._3UbM065ICI_4mCzfMjfMRU > .form > .buttons {
      display: flex;
      justify-content: space-between;
      width: 100%; }

/*# sourceMappingURL=Body.css.map */

._356dncR2_GiuBW3I-rv7Ca {
  display: inline-block;
  background: linear-gradient(135deg, #cacaca, #fbfbfb);
  border-radius: 50%;
  color: #778da7;
  padding: 8px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  margin: 6px;
  text-align: center; }
  ._356dncR2_GiuBW3I-rv7Ca.button {
    cursor: pointer;
    background: #fff;
    padding: 6px;
    box-shadow: 0 1px 2px 0 #afafc5;
    height: 42px;
    width: 42px;
    font-size: 24px;
    transition: 150ms; }
    ._356dncR2_GiuBW3I-rv7Ca.button:hover {
      background-color: #eee; }
  ._356dncR2_GiuBW3I-rv7Ca.edit {
    color: #ff7d38; }
  ._356dncR2_GiuBW3I-rv7Ca.cancel {
    color: #ef4836; }
  ._356dncR2_GiuBW3I-rv7Ca.save {
    color: #0392f5; }
  ._356dncR2_GiuBW3I-rv7Ca.small {
    height: 30px;
    width: 30px;
    font-size: 16px; }

/*# sourceMappingURL=Icon.css.map */

._2yMU3E4W5PT411BjPiMmpm label {
  display: block;
  font-size: 14px;
  padding: 4px 0px;
  font-family: "Open Sans", sans-serif; }

._1IXXHKlb1OUZWotEgqOpI- {
  border: 1px solid #dfe1e2;
  background-color: #fff;
  padding: 8px;
  height: 36px;
  font-family: "Roboto", sans-serif; }
  ._1IXXHKlb1OUZWotEgqOpI-.wide {
    width: 100%; }
  ._1IXXHKlb1OUZWotEgqOpI-.small {
    width: 70%; }
  ._1IXXHKlb1OUZWotEgqOpI-.checkbox {
    width: 18px;
    height: 18px;
    border: 2px solid #8c969f;
    border-radius: 3px;
    -webkit-appearance: none; }
    ._1IXXHKlb1OUZWotEgqOpI-.checkbox.checked {
      background-color: #ff7d38; }

/*# sourceMappingURL=Input.css.map */

._2F_2MVS5K0rAPcruxr5bJV label {
  display: block;
  font-size: 14px;
  padding: 4px 0px; }

._2Qb9U8EwvzB46Ldwzoc5_m {
  line-height: 40px;
  text-decoration: none; }
  ._2Qb9U8EwvzB46Ldwzoc5_m:visited {
    color: inherit; }
  ._2Qb9U8EwvzB46Ldwzoc5_m > span.fa {
    margin-right: 8px;
    color: #8c969f; }

/*# sourceMappingURL=Span.css.map */

._5qpf1HmF486JiFmmwQqx6 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  ._5qpf1HmF486JiFmmwQqx6 label, ._5qpf1HmF486JiFmmwQqx6 input, ._5qpf1HmF486JiFmmwQqx6 h1, ._5qpf1HmF486JiFmmwQqx6 h2, ._5qpf1HmF486JiFmmwQqx6 h3, ._5qpf1HmF486JiFmmwQqx6 h4, ._5qpf1HmF486JiFmmwQqx6 h5, ._5qpf1HmF486JiFmmwQqx6 h6 {
    width: 100%; }
  ._5qpf1HmF486JiFmmwQqx6 label {
    display: block;
    font-size: 14px;
    padding: 2px 0px; }
  ._5qpf1HmF486JiFmmwQqx6 input, ._5qpf1HmF486JiFmmwQqx6 select {
    margin: 2px 0px; }
  ._5qpf1HmF486JiFmmwQqx6 select {
    align-self: flex-start;
    min-width: 175px; }
  ._5qpf1HmF486JiFmmwQqx6 > div {
    width: 100%; }
  ._5qpf1HmF486JiFmmwQqx6 .buttons {
    display: flex;
    justify-content: space-around;
    align-items: center; }

/*# sourceMappingURL=Form.css.map */

._6NkRlvQifq4C0mRbXjbDg {
  flex: 1 1;
  position: relative;
  display: flex; }
  ._6NkRlvQifq4C0mRbXjbDg > label {
    display: block;
    position: absolute;
    top: -24px; }
  ._6NkRlvQifq4C0mRbXjbDg > input {
    width: 100%;
    height: 36px;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #dfe1e2; }
    ._6NkRlvQifq4C0mRbXjbDg > input::-webkit-input-placeholder {
      color: #8c969f; }
    ._6NkRlvQifq4C0mRbXjbDg > input::placeholder {
      color: #8c969f; }
  ._6NkRlvQifq4C0mRbXjbDg.selectable > select {
    width: auto;
    min-width: 92px;
    background-color: #f6f8fa;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none; }
  ._6NkRlvQifq4C0mRbXjbDg.selectable > input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }

/*# sourceMappingURL=SearchBar.css.map */

._2U0lD68jRZHRaGzsnqcuqK {
  max-height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  ._2U0lD68jRZHRaGzsnqcuqK > .icon {
    position: absolute;
    right: -50px;
    top: -50px; }
  ._2U0lD68jRZHRaGzsnqcuqK > .header {
    display: flex;
    justify-content: center;
    align-items: center; }
    ._2U0lD68jRZHRaGzsnqcuqK > .header > h2 {
      font-weight: normal; }
    ._2U0lD68jRZHRaGzsnqcuqK > .header > .fa {
      cursor: pointer; }
  ._2U0lD68jRZHRaGzsnqcuqK > .body {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 90%; }
    ._2U0lD68jRZHRaGzsnqcuqK > .body > select {
      padding-left: 8px; }
    ._2U0lD68jRZHRaGzsnqcuqK > .body > .buttons {
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: space-between; }

/*# sourceMappingURL=FormModal.css.map */

.jXwG-SMy3m2hrXqzrxheB {
  overflow-x: scroll; }

._3mgQH0hotNP7eWfX4ljKFP {
  box-shadow: 0 1px 2px 0 #afafc5;
  border-radius: 5px;
  width: 95%;
  margin: 20px auto;
  border-collapse: collapse;
  background-color: #fff; }
  ._3mgQH0hotNP7eWfX4ljKFP .checkbox {
    text-align: center; }
  ._3mgQH0hotNP7eWfX4ljKFP > thead > tr {
    height: 40px;
    text-align: left;
    text-transform: uppercase;
    color: #aaa;
    font-size: 12px; }
    ._3mgQH0hotNP7eWfX4ljKFP > thead > tr th {
      padding: 10px; }
      ._3mgQH0hotNP7eWfX4ljKFP > thead > tr th:not(:last-child) {
        border-right: 1px solid #dfe1e2; }
  ._3mgQH0hotNP7eWfX4ljKFP > tbody > tr {
    height: 61px; }
    ._3mgQH0hotNP7eWfX4ljKFP > tbody > tr td {
      white-space: nowrap;
      border: 1px solid #dfe1e2;
      padding: 10px; }
      ._3mgQH0hotNP7eWfX4ljKFP > tbody > tr td.bold {
        font-weight: bold; }
    ._3mgQH0hotNP7eWfX4ljKFP > tbody > tr:nth-child(odd) {
      background-color: #f6f8fa; }
  ._3mgQH0hotNP7eWfX4ljKFP.hoverable > tbody > tr:hover {
    cursor: pointer;
    background-color: #cde6fa; }
  ._3mgQH0hotNP7eWfX4ljKFP.borderless thead tr th {
    border-color: transparent; }
  ._3mgQH0hotNP7eWfX4ljKFP.borderless tbody tr td {
    border-color: transparent; }

/*# sourceMappingURL=Table.css.map */

._3y4mcuA6KNGUSaorbUTAuu {
  display: flex;
  position: relative; }
  ._3y4mcuA6KNGUSaorbUTAuu > .date-container {
    width: 60px;
    padding: 20px;
    border-right: 1.5px solid #eee; }
    ._3y4mcuA6KNGUSaorbUTAuu > .date-container > .date {
      width: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      ._3y4mcuA6KNGUSaorbUTAuu > .date-container > .date > .day {
        font-weight: bold; }
  ._3y4mcuA6KNGUSaorbUTAuu > .dot {
    background-color: #fff;
    border-radius: 50%;
    padding: 3px;
    border: 4px solid #ff7d38;
    position: absolute;
    top: 28px;
    left: 53px; }
  ._3y4mcuA6KNGUSaorbUTAuu > .content {
    margin: 20px;
    max-width: 400px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 1px 2px 0 #afafc5; }
    ._3y4mcuA6KNGUSaorbUTAuu > .content::after {
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-right-color: #ff7d38;
      border-width: 8px;
      top: 27px;
      left: 65px; }
    ._3y4mcuA6KNGUSaorbUTAuu > .content > .title {
      padding: 12px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      background-color: #ff7d38; }
      ._3y4mcuA6KNGUSaorbUTAuu > .content > .title > span > span.fa {
        margin-right: 6px; }
    ._3y4mcuA6KNGUSaorbUTAuu > .content > .body {
      padding: 12px;
      color: #000; }
  ._3y4mcuA6KNGUSaorbUTAuu.visit > .dot {
    border-color: #a0a9b0; }
  ._3y4mcuA6KNGUSaorbUTAuu.visit > .content::after {
    border-right-color: #a0a9b0; }
  ._3y4mcuA6KNGUSaorbUTAuu.visit > .content > .title {
    background-color: #a0a9b0; }
  ._3y4mcuA6KNGUSaorbUTAuu.visit > .content > .body > span {
    display: block;
    color: #666; }
    ._3y4mcuA6KNGUSaorbUTAuu.visit > .content > .body > span > .fa {
      text-align: center;
      padding: 5px;
      font-size: 20px;
      width: 30px; }
  ._3y4mcuA6KNGUSaorbUTAuu.visit > .button {
    height: 100%;
    align-self: center; }

/*# sourceMappingURL=DateBox.css.map */

._10EsPAqWwgX5X9n0BZ5lo3 {
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  display: none; }
  ._10EsPAqWwgX5X9n0BZ5lo3.active {
    display: block;
    position: absolute; }

/*# sourceMappingURL=Overlay.css.map */

._19HETNZAYVFbUUFlmQW4H5 {
  position: relative; }
  ._19HETNZAYVFbUUFlmQW4H5 > label {
    display: block;
    position: absolute;
    top: -24px; }

._kN9PG9gDpbt3pMGjnocS {
  min-width: 110px;
  width: 100%;
  height: 36px;
  padding: 4px;
  padding-left: 10px;
  border-radius: 4px;
  border: 1px solid #dfe1e2;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 14px) calc(1em - 2px), calc(100% - 10px) calc(1em - 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat; }
  ._kN9PG9gDpbt3pMGjnocS.table {
    margin: auto 15px;
    padding: auto 5px;
    text-shadow: 1px 1px #fff; }

/*# sourceMappingURL=Selector.css.map */

._3SSuC28fiE76o_BDO2ulRn {
  width: 100%;
  margin: 10px 0px;
  height: 100px; }

/*# sourceMappingURL=TextArea.css.map */

.WMsL7V0pq6Embp6hQcJIA > .fa {
  font-size: 16px;
  cursor: pointer;
  position: relative; }
  .WMsL7V0pq6Embp6hQcJIA > .fa.top {
    bottom: 5px;
    left: 9px; }
  .WMsL7V0pq6Embp6hQcJIA > .fa.bottom {
    top: 5px; }
  .WMsL7V0pq6Embp6hQcJIA > .fa.active {
    color: #000; }

/*# sourceMappingURL=SortableHead.css.map */

._3VmpiZT12rynWXlknMX4aI {
  box-shadow: 0 1px 2px 0 #afafc5;
  border-radius: 5px; }
  ._3VmpiZT12rynWXlknMX4aI > button {
    background-color: #fff;
    background: #fff;
    padding: 10px 14px;
    border-color: transparent;
    color: #888;
    font-size: 12px; }
    ._3VmpiZT12rynWXlknMX4aI > button:hover {
      background-color: #eee; }
    ._3VmpiZT12rynWXlknMX4aI > button.selected {
      background-color: #a0a9b0;
      color: #fff; }
    ._3VmpiZT12rynWXlknMX4aI > button:not(:first-child) {
      border-left: none; }
    ._3VmpiZT12rynWXlknMX4aI > button:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px; }
    ._3VmpiZT12rynWXlknMX4aI > button:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; }

/*# sourceMappingURL=ButtonGroup.css.map */

._1PXYnkhUOCwoWbZ2VGefq1 {
  padding: 0px 20px;
  margin: 20px 0px;
  display: flex;
  height: auto;
  flex-direction: column; }
  ._1PXYnkhUOCwoWbZ2VGefq1 > .titles {
    font-size: 14px;
    line-height: 26px;
    color: #8c969f; }
  ._1PXYnkhUOCwoWbZ2VGefq1 > .content {
    font-size: 30px;
    line-height: 40px;
    height: 40px;
    color: #25292d; }
  ._1PXYnkhUOCwoWbZ2VGefq1.small {
    padding: 0px 10px;
    margin: 10px 0px; }
    ._1PXYnkhUOCwoWbZ2VGefq1.small > .content {
      font-size: 22px;
      line-height: 30px; }
  @media screen and (max-width: 768px) {
    ._1PXYnkhUOCwoWbZ2VGefq1 {
      padding: 0px 5px;
      margin: 10px 0px;
      display: flex;
      height: auto;
      flex-direction: column; }
      ._1PXYnkhUOCwoWbZ2VGefq1 > .titles {
        font-size: 14px;
        line-height: 26px;
        color: #8c969f; }
      ._1PXYnkhUOCwoWbZ2VGefq1 > .content {
        font-size: 30px;
        line-height: 40px;
        height: 40px;
        color: #25292d; }
      ._1PXYnkhUOCwoWbZ2VGefq1.small {
        padding: 0px 10px;
        margin: 10px 0px; }
        ._1PXYnkhUOCwoWbZ2VGefq1.small > .content {
          font-size: 22px;
          line-height: 30px; } }

/*# sourceMappingURL=SummaryItem.css.map */

._29K5YtOwxoXOGiZvhSl4zC > input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 1px;
  z-index: -1; }

._1c23nphJjst5DcnA0QHKwQ {
  height: 39px;
  display: flex;
  align-items: center; }
  ._1c23nphJjst5DcnA0QHKwQ > div {
    width: 100%; }

._1tsQl-Chy0aKQgo9pm6y4o {
  text-align: center;
  border: none;
  border-radius: 3px;
  color: #fff;
  background-color: #3bcf5d;
  padding: 12px 18px;
  font-size: 14px;
  min-width: 175px;
  text-decoration: none;
  cursor: pointer;
  transition: all 150ms; }
  ._1tsQl-Chy0aKQgo9pm6y4o:hover {
    box-shadow: 1px 1px 8px #888888; }
  ._1tsQl-Chy0aKQgo9pm6y4o.wide {
    width: 100%;
    border-radius: 0px; }
  ._1tsQl-Chy0aKQgo9pm6y4o.large {
    padding: 14px 24px;
    border-radius: 3px; }
  ._1tsQl-Chy0aKQgo9pm6y4o.confirm {
    background-color: #0392f5; }
  ._1tsQl-Chy0aKQgo9pm6y4o.inactive {
    width: auto;
    cursor: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5; }
    ._1tsQl-Chy0aKQgo9pm6y4o.inactive:hover {
      box-shadow: none; }
  ._1tsQl-Chy0aKQgo9pm6y4o > .fa {
    margin-right: 10px; }

/*# sourceMappingURL=FileUpload.css.map */

._3mZ5EYod077MRMzVPjslkw {
  display: inline-flex;
  justify-content: space-between; }

.dB2_XEfFiAYT9iXlT4UaP {
  height: 10px; }

.body {
  padding: 20px; }
  .body h3 {
    font-size: 28px;
    margin: 0; }
  .body .form {
    margin-top: 20px;
    max-width: 400px; }
    .body .form label {
      display: block;
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: bold; }
    .body .form .input {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px; }
      .body .form .input:focus {
        outline: none;
        box-shadow: 0 0 5px #aaa; }
    .body .form .separator {
      margin: 15px 0;
      height: 1px;
      background-color: #ccc; }
    .body .form .inlineBtn {
      display: flex;
      justify-content: space-between; }
      .body .form .inlineBtn button {
        width: 48%;
        margin: 0; }

/*# sourceMappingURL=profile.css.map */

._2jVECe2_TnaIL_Kq48E5yY {
  display: flex;
  height: auto;
  margin: 50px auto;
  text-align: center; }

.Contact-info {
  margin: 3vh; }
  .Contact-info .Contact-icon {
    margin: 5px; }

.card {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .card .Business-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
    .card .Business-card .Logo-container {
      margin-bottom: 20px; }
      .card .Business-card .Logo-container img {
        width: 80px;
        height: 80px;
        border-radius: 50%; }
    .card .Business-card .Basic-info {
      text-align: center; }
      .card .Business-card .Basic-info .Contact-info {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 20px; }
        .card .Business-card .Basic-info .Contact-info .ContactInfo {
          margin: 10px;
          display: flex;
          align-items: center; }
          .card .Business-card .Basic-info .Contact-info .ContactInfo img {
            width: 20px;
            height: 20px;
            margin-right: 8px; }

/*# sourceMappingURL=showcase.css.map */

.inner_content {
  padding: 20px;
  font-family: Arial, sans-serif; }
  .inner_content .innerPage_container header {
    padding: 20px 0;
    border-bottom: 1px solid #ccc; }
    .inner_content .innerPage_container header h2 {
      font-size: 28px;
      margin: 0; }
    .inner_content .innerPage_container header p {
      font-size: 16px;
      margin: 10px 0; }
  .inner_content .innerPage_container .page_container .alert {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px; }
    .inner_content .innerPage_container .page_container .alert.alert-success {
      background-color: #dff0d8;
      color: #3c763d; }
    .inner_content .innerPage_container .page_container .alert.alert-danger {
      background-color: #f2dede;
      color: #a94442; }
  .inner_content .innerPage_container .page_container .containbox_shadow {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px; }
    @media screen and (max-width: 768px) {
      .inner_content .innerPage_container .page_container .containbox_shadow {
        padding: 5px; } }
    .inner_content .innerPage_container .page_container .containbox_shadow .settingcontent_block {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #ccc; }
      .inner_content .innerPage_container .page_container .containbox_shadow .settingcontent_block h3 {
        font-size: 24px;
        margin: 10px 0; }
      .inner_content .innerPage_container .page_container .containbox_shadow .settingcontent_block .form .row {
        justify-content: space-evenly;
        align-items: baseline;
        display: flex;
        flex-direction: row; }
        .inner_content .innerPage_container .page_container .containbox_shadow .settingcontent_block .form .row .span3 {
          justify-content: center;
          align-items: center;
          margin: 0 7% auto 7%; }
        .inner_content .innerPage_container .page_container .containbox_shadow .settingcontent_block .form .row .license {
          margin: 0; }
        .inner_content .innerPage_container .page_container .containbox_shadow .settingcontent_block .form .row .span2 {
          justify-content: center;
          align-items: center;
          margin: 0 7% auto 7%; }
        .inner_content .innerPage_container .page_container .containbox_shadow .settingcontent_block .form .row .input-feild {
          width: 100%;
          padding: 10px;
          font-size: 16px;
          border: 1px solid #ccc;
          border-radius: 4px; }
          .inner_content .innerPage_container .page_container .containbox_shadow .settingcontent_block .form .row .input-feild:focus {
            outline: none;
            box-shadow: 0 0 5px #aaa; }
        .inner_content .innerPage_container .page_container .containbox_shadow .settingcontent_block .form .row .inputFile {
          position: relative; }
          .inner_content .innerPage_container .page_container .containbox_shadow .settingcontent_block .form .row .inputFile input[type="file"] {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%; }
          .inner_content .innerPage_container .page_container .containbox_shadow .settingcontent_block .form .row .inputFile .addfile {
            width: 100px;
            height: 100px;
            border: 1px solid #ccc;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto; }
            .inner_content .innerPage_container .page_container .containbox_shadow .settingcontent_block .form .row .inputFile .addfile img {
              width: 100%;
              height: 100%;
              object-fit: cover; }
    .inner_content .innerPage_container .page_container .containbox_shadow .settingb_group {
      display: flex;
      flex-direction: row; }
      .inner_content .innerPage_container .page_container .containbox_shadow .settingb_group .setting_button {
        text-align: right; }
        .inner_content .innerPage_container .page_container .containbox_shadow .settingb_group .setting_button button {
          padding: 10px 20px;
          font-size: 16px;
          border: none;
          border-radius: 4px;
          cursor: pointer; }
          .inner_content .innerPage_container .page_container .containbox_shadow .settingb_group .setting_button button.active {
            background-color: #007bff;
            color: #fff; }
          .inner_content .innerPage_container .page_container .containbox_shadow .settingb_group .setting_button button.fr {
            margin-left: 10px; }
          .inner_content .innerPage_container .page_container .containbox_shadow .settingb_group .setting_button button.fl {
            margin-right: 10px; }
    .inner_content .innerPage_container .page_container .containbox_shadow .BankDetails .tblBankDetails {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0; }
      .inner_content .innerPage_container .page_container .containbox_shadow .BankDetails .tblBankDetails th {
        padding: 10px;
        font-weight: bold;
        border-bottom: 1px solid #ccc; }
      .inner_content .innerPage_container .page_container .containbox_shadow .BankDetails .tblBankDetails td {
        padding: 10px;
        border-bottom: 1px solid #ccc;
        text-align: center; }
      .inner_content .innerPage_container .page_container .containbox_shadow .BankDetails .tblBankDetails .tblName {
        width: 30%; }
      .inner_content .innerPage_container .page_container .containbox_shadow .BankDetails .tblBankDetails .tblCenter {
        width: 20%; }
      .inner_content .innerPage_container .page_container .containbox_shadow .BankDetails .tblBankDetails .tblAction {
        width: 30%; }
        .inner_content .innerPage_container .page_container .containbox_shadow .BankDetails .tblBankDetails .tblAction button {
          margin-right: 10px; }

/*# sourceMappingURL=setting-page.css.map */

._1yv1Cm0lFH6IANNAR_uZtI .summary {
  justify-content: space-evenly;
  align-items: center;
  margin: auto 1em;
  border-right: 1px solid #dfe1e2; }
  @media screen and (max-width: 768px) {
    ._1yv1Cm0lFH6IANNAR_uZtI .summary {
      align-items: center;
      justify-content: flex-start;
      border-right: none;
      margin: 0; } }

.KdWVru5fV7w-j1RTvsBwa {
  display: flex;
  justify-content: center;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content; }

._2m32wvuz-tK3XtzeOEM21d {
  margin: auto;
  width: 95%; }

._2BTV_6lwzxBhAZsSptlKwP {
  width: 1px; }
  ._2BTV_6lwzxBhAZsSptlKwP > .button {
    background-color: #a0a9b0;
    min-width: inherit;
    padding: 12px; }

/* Define any global styles or variables here */
/* Style for the main container */
/* Style for the action box */
.action-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  /* Adjust the color as needed */
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 2rem; }
  @media screen and (max-width: 768px) {
    .action-box {
      display: flex;
      overflow-x: scroll;
      justify-content: space-evenly;
      align-items: center;
      background-color: #f7f7f7;
      /* Adjust the color as needed */
      border-radius: 4px;
      padding: 0;
      margin-bottom: 5%; } }

/* Style for the search bar */
.search-bar {
  flex: 1 1;
  margin-right: 1rem; }
  .search-bar .MuiInputBase-input {
    font-size: 1rem;
    color: #333;
    /* Adjust the color as needed */ }

/* Style for the action button */
.action-button {
  background-color: #007bff;
  /* Adjust the color as needed */
  color: #ffffff;
  /* Adjust the color as needed */
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease; }
  .action-button:hover {
    background-color: #0056b3;
    /* Adjust the color as needed */ }

/* Style for the table */
.table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px; }
  .table thead {
    background-color: #f7f7f7;
    /* Adjust the color as needed */
    font-weight: bold; }
    .table thead th {
      padding: 1rem;
      text-align: left; }
  .table tbody tr {
    transition: background-color 0.3s ease; }
    .table tbody tr:hover {
      background-color: #f7f7f7;
      /* Adjust the color as needed */ }
    .table tbody tr td {
      padding: 1rem; }
      .table tbody tr td.bold {
        font-weight: bold; }
      .table tbody tr td .selector {
        /* Add any specific styles for the selector component here */ }
      .table tbody tr td .details-cell {
        text-align: center; }
      .table tbody tr td button {
        background-color: #ffffff;
        color: #007bff;
        /* Adjust the color as needed */
        border: 1px solid #007bff;
        /* Adjust the color as needed */
        padding: 0.75rem 1.5rem;
        border-radius: 4px;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease; }
        .table tbody tr td button:hover {
          background-color: #007bff;
          /* Adjust the color as needed */
          color: #ffffff;
          /* Adjust the color as needed */ }

/* Style for the PaymentHistory component (you can add this later) */
.payment-history {
  /* Add any styles specific to the PaymentHistory component here */ }

/*# sourceMappingURL=accounts.css.map */

.payment-history-container {
  padding: 20px; }
  .payment-history-container .table-container {
    margin: 20px 1em;
    padding: 20px; }
    .payment-history-container .table-container table {
      width: 100%;
      border-collapse: collapse;
      font-size: 16px; }
      .payment-history-container .table-container table th, .payment-history-container .table-container table td {
        padding: 10px;
        text-align: left;
        border-bottom: 1px solid #ddd; }
      .payment-history-container .table-container table th {
        font-weight: bold; }
    @media screen and (max-width: 768px) {
      .payment-history-container .table-container {
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        padding: 10px;
        width: 35vh; }
        .payment-history-container .table-container table {
          width: 10em;
          overflow-y: scroll;
          font-size: 16px; }
          .payment-history-container .table-container table th, .payment-history-container .table-container table td {
            margin: auto;
            padding: 5px;
            text-align: left;
            border-bottom: 1px solid #ddd; }
          .payment-history-container .table-container table th {
            font-weight: bold; } }
  .payment-history-container .chart-container {
    margin-top: 30px; }

/*# sourceMappingURL=payment-history.css.map */



/*# sourceMappingURL=settings.css.map */

.ZbAxD7AH63HEnl5bXmwxD {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column; }

/*# sourceMappingURL=not-found.css.map */

._3FcEKSpCrYbdHJm9gCe1Dk {
  margin: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow-y: scroll; }

/*# sourceMappingURL=payment-form.css.map */

.stripe-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px; }
  .stripe-payment h2 {
    font-size: 24px;
    margin-bottom: 20px; }
  .stripe-payment label {
    display: block;
    font-size: 16px;
    margin-bottom: 10px; }
  .stripe-payment .StripeElement {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    margin-bottom: 10px;
    width: 100%; }
  .stripe-payment .buttons {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .stripe-payment .buttons button {
      flex-grow: 1;
      margin: 0 5px; }

/*# sourceMappingURL=stripe.css.map */

.header {
  background-color: transparent; }

h3 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #333;
  /* Adjust the color as needed */ }

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto; }
  .body .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem; }
    .body .form label {
      font-weight: bold;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      color: #555;
      /* Adjust the color as needed */ }
    .body .form input {
      width: 100%;
      padding: 0.5rem;
      margin-bottom: 1rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 1rem;
      color: #333;
      /* Adjust the color as needed */ }
    .body .form .buttons {
      display: flex;
      justify-content: space-evenly;
      margin-top: 2rem; }
      .body .form .buttons button {
        margin: 0 0.5rem;
        padding: 1rem 2rem;
        font-size: 1rem;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease; }
        .body .form .buttons button:hover {
          background-color: #007bff;
          /* Adjust the color as needed */
          color: #ffffff;
          /* Adjust the color as needed */ }
      .body .form .buttons .cancel {
        background-color: #ffffff;
        color: #007bff;
        /* Adjust the color as needed */
        border: 1px solid #007bff;
        /* Adjust the color as needed */ }
      .body .form .buttons .create-account {
        background-color: #007bff;
        /* Adjust the color as needed */
        color: #ffffff;
        /* Adjust the color as needed */
        border: none; }
        .body .form .buttons .create-account:disabled {
          background-color: #ccc;
          /* Adjust the color as needed */
          color: #ffffff;
          /* Adjust the color as needed */
          cursor: not-allowed; }

/*# sourceMappingURL=account-form.css.map */

.payment-portal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  grid-gap: 1.5rem;
  gap: 1.5rem; }
  .payment-portal .additional-credits,
  .payment-portal .total-price,
  .payment-portal .payment-method {
    width: 100%;
    max-width: 400px;
    padding: 1rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px; }
    .payment-portal .additional-credits h6,
    .payment-portal .total-price h6,
    .payment-portal .payment-method h6 {
      margin-bottom: 1rem; }
  .payment-portal .additional-credits input[type="number"] {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px; }
  .payment-portal .total-price h5 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #3f51b5; }
  .payment-portal .payment-method {
    display: flex;
    flex-direction: column; }
    .payment-portal .payment-method .MuiFormControlLabel-root {
      margin-bottom: 0.5rem; }
  .payment-portal .buttons-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px; }
  .payment-portal .purchase-button {
    flex: 1 1;
    margin-right: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px; }
  .payment-portal .add-payment-link {
    color: #3f51b5;
    text-decoration: none;
    font-size: 0.875rem;
    margin-top: 1rem;
    text-align: center;
    transition: color 0.3s ease; }
    .payment-portal .add-payment-link:hover {
      color: #1976d2; }

/*# sourceMappingURL=make-payment.css.map */

.paymentPortal {
  padding: 20px;
  max-width: 400px;
  margin: 0 auto; }
  .paymentPortal .typography {
    padding: 5%; }
  .paymentPortal .textField {
    margin: auto auto 5% auto; }
  .paymentPortal h5 {
    margin-bottom: 20px; }
  .paymentPortal .MuiFormControl-root {
    width: 100%; }
  .paymentPortal .MuiFormControlLabel-root {
    display: block;
    margin-bottom: 10px; }
  .paymentPortal .paymentButton {
    margin-top: 20px;
    background-color: #007bff;
    color: #fff; }
    .paymentPortal .paymentButton:hover {
      background-color: #0056b3; }
  .paymentPortal .button-group {
    display: flex;
    justify-content: space-evenly; }
  .paymentPortal .valueButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3% auto; }

/*# sourceMappingURL=payment-portal.css.map */

.main-btn {
  width: 185px;
  letter-spacing: 1.5px;
  border-radius: 3px;
  margin-top: 1rem; }

.btn:hover {
  opacity: 0.8; }

.credit-card {
  -webkit-animation: float 3s ease-in-out infinite;
          animation: float 3s ease-in-out infinite; }

@-webkit-keyframes float {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px); }
  50% {
    -webkit-transform: translatey(-10px);
            transform: translatey(-10px); }
  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px); } }

@keyframes float {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px); }
  50% {
    -webkit-transform: translatey(-10px);
            transform: translatey(-10px); }
  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px); } }

/*# sourceMappingURL=bank-account.css.map */



/*# sourceMappingURL=forgot-password.css.map */

/* Import fonts and reset styles if needed */
/* Global styles */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0; }

/* Component styles */
.container {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

h5 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem; }

p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem; }

.emailInput {
  margin-bottom: 1rem; }

.errorMessage {
  color: red;
  margin-bottom: 1rem; }

.successMessage {
  color: green;
  margin-bottom: 1rem; }

.submitButton {
  background-color: #2196F3;
  color: #fff;
  font-weight: bold;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease; }
  .submitButton:hover {
    background-color: #1976D2; }

/* Responsive styles */
@media screen and (max-width: 768px) {
  .container {
    margin-top: 3rem; }

  h5 {
    font-size: 1.2rem; }

  p {
    font-size: 0.9rem; }

  .submitButton {
    padding: 0.8rem 1.5rem; } }

/*# sourceMappingURL=reset-password.css.map */

/* PhoneVerification.scss */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem; }
  .container h4 {
    margin-bottom: 1.5rem;
    font-family: 'Roboto', sans-serif; }
  .container .phone-input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .container .phone-input-container .PhoneInput {
      width: 100%;
      max-width: 300px;
      padding: 0.5rem;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 5px; }
      .container .phone-input-container .PhoneInput:focus {
        outline: none; }
    .container .phone-input-container .otp-input-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem; }
      .container .phone-input-container .otp-input-container .otp-input {
        display: flex;
        justify-content: center;
        margin: auto;
        padding: 1vh;
        box-sizing: border-box;
        font-size: 5vh; }
        .container .phone-input-container .otp-input-container .otp-input input {
          width: 40px;
          height: 40px;
          font-size: 20px;
          text-align: center;
          border: 1px solid #ccc;
          border-radius: 5px;
          margin: 0 5px; }
          .container .phone-input-container .otp-input-container .otp-input input:focus {
            outline: none; }
        .container .phone-input-container .otp-input-container .otp-input span {
          margin: 0 5px; }
  .container .verify-button {
    margin-top: 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer; }
    .container .verify-button:hover {
      background-color: #0056b3; }
    .container .verify-button:focus {
      outline: none; }

/*# sourceMappingURL=VerifyPhoneModal.css.map */



/*# sourceMappingURL=NoteModal.css.map */

._31x4zA1S4gv5pLocPqFXxT {
  display: flex;
  justify-content: center;
  text-align: center;
  overflow-y: scroll; }
  ._31x4zA1S4gv5pLocPqFXxT .buttons {
    margin: 20px; }

.UWbwv8ollC0REU07J37T2 {
  margin: 3vh 3vh;
  width: 400px;
  font: 14px "Lucida Grande", Helvetica, Arial, sans-serif; }

._2BZhZOM5XxkJyzQA9UElFS {
  padding: 5px 10px;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #ddd23b;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
  transition: all 0.5s;
  cursor: pointer; }

._2BZhZOM5XxkJyzQA9UElFS:active {
  background-color: #9e8825;
  box-shadow: 0 5px #666;
  -webkit-transform: translateY(4px);
          transform: translateY(4px); }

._2BZhZOM5XxkJyzQA9UElFS span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s; }

._2BZhZOM5XxkJyzQA9UElFS:hover span {
  padding-right: 25px; }

._2BZhZOM5XxkJyzQA9UElFS span:after {
  content: '\BB';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s; }

._2BZhZOM5XxkJyzQA9UElFS:hover span:after {
  opacity: 1;
  right: 0; }

a {
  color: #00B7FF; }

._12b-PY6AYiEcepVW3Opx6n {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem; }

label {
  display: block;
  font-weight: bold; }

input {
  width: 75px; }

/*# sourceMappingURL=accountsModal.css.map */

._3uETP9PaFMpxoELOpLJ32Q {
  position: absolute;
  top: -50px;
  left: 20px;
  display: flex; }
  ._3uETP9PaFMpxoELOpLJ32Q > .tab {
    padding: 14px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    border-top: 4px solid transparent; }
    ._3uETP9PaFMpxoELOpLJ32Q > .tab.active {
      background-color: #fff;
      border: 1px solid #dfe1e2;
      border-top: 4px solid #ff7d38;
      border-bottom: none;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px; }
    ._3uETP9PaFMpxoELOpLJ32Q > .tab:not(.active) {
      cursor: pointer; }

/*# sourceMappingURL=TabsSelector.css.map */

._2S6YvxaezM30TQTj0ZWaGB {
  border: 1px solid #dfe1e2;
  position: relative; }
  ._2S6YvxaezM30TQTj0ZWaGB.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto; }
  ._2S6YvxaezM30TQTj0ZWaGB > .main {
    background-color: #fff;
    border-top: 1px solid #dfe1e2; }

/*# sourceMappingURL=SwitchTable.css.map */

._3oyxnRU-vRSIkUHdFug5ql {
  width: 250px;
  min-width: 150px; }
  ._3oyxnRU-vRSIkUHdFug5ql > div {
    display: flex;
    align-items: center;
    height: 40px; }
    ._3oyxnRU-vRSIkUHdFug5ql > div:not(:last-child) {
      border-bottom: 1px solid #dfe1e2; }
    ._3oyxnRU-vRSIkUHdFug5ql > div > .fa {
      padding: 8px;
      width: 32px; }

/*# sourceMappingURL=ContactBox.css.map */

._1mEwdHKgyQO8jZquzWjriI > .body > .line {
  display: flex;
  align-items: center; }
  ._1mEwdHKgyQO8jZquzWjriI > .body > .line > select {
    width: 175px;
    min-width: inherit; }

/*# sourceMappingURL=AddressModal.css.map */



/*# sourceMappingURL=AddressLine.css.map */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
  background-color: #222222;
  height: 100vh;
  color: #fafafa; }

.App-title {
  font-size: 50px;
  padding: 25px 0; }

.Timers {
  display: flex;
  justify-content: center; }

@media (max-width: 900px) {
  .Timers {
    flex-direction: column;
    align-items: center; } }
@media (max-width: 900px) {
  .Stopwatch {
    margin-bottom: 40px; } }
.Countdown,
.Stopwatch {
  margin-left: 30px;
  margin-right: 30px;
  border: 2px solid grey;
  border-radius: 4px;
  padding: 20px;
  width: 400px;
  background-color: #161b1f;
  box-shadow: 0 3px 6px #0c0c0c; }

.Countdown {
  padding-top: 10px; }

.Countdown-header,
.Stopwatch-header {
  font-size: 40px;
  font-weight: bold; }

.Stopwatch button {
  padding: 12px 32px;
  font-size: 20px; }

.Stopwatch-display {
  padding: 40px 0;
  font-size: 48px; }

.Countdown-display {
  margin-top: 5px;
  margin-bottom: 20px; }

.Countdown-display button {
  margin: 0 15px;
  border: solid 1px transparent;
  border-radius: 2px;
  padding: 4px 16px;
  color: #ffffff;
  font-size: 16px; }

.Countdown-label {
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 10px; }

.Countdown-time {
  font-size: 36px;
  margin: 5px 0; }

/*# sourceMappingURL=Timer.css.map */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
  background-color: #222222;
  height: 100vh;
  color: #fafafa; }

.App-title {
  font-size: 50px;
  padding: 25px 0; }

.Timers {
  display: flex;
  justify-content: center; }

@media (max-width: 900px) {
  .Timers {
    flex-direction: column;
    align-items: center; } }
@media (max-width: 900px) {
  .Stopwatch {
    margin-bottom: 40px; } }
.Countdown,
.Stopwatch {
  margin-left: 30px;
  margin-right: 30px;
  border: 2px solid grey;
  border-radius: 4px;
  padding: 20px;
  width: 400px;
  background-color: #161b1f;
  box-shadow: 0 3px 6px #0c0c0c; }

.Countdown {
  padding-top: 10px; }

.Countdown-header,
.Stopwatch-header {
  font-size: 40px;
  font-weight: bold; }

.Stopwatch button {
  padding: 12px 32px;
  font-size: 20px; }

.Stopwatch-display {
  padding: 40px 0;
  font-size: 48px; }

.Countdown-display {
  margin-top: 5px;
  margin-bottom: 20px; }

.Countdown-display button {
  margin: 0 15px;
  border: solid 1px transparent;
  border-radius: 2px;
  padding: 4px 16px;
  color: #ffffff;
  font-size: 16px; }

.Countdown-display button:hover {
  background-color: #fff; }

.Countdown-label {
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 10px; }

.Countdown-time {
  font-size: 36px;
  margin: 5px 0; }

/*# sourceMappingURL=Countdown.css.map */

.root {
  padding: 20px;
  max-width: 400px;
  margin: 0 auto; }
  .root .tabPanel {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 20px; }
  .root .formContainer {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .root .formInput {
    width: 100%;
    margin: 10px 0;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px; }
  .root .submitButton {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer; }
  .root .submitButton:hover {
    background-color: #0056b3; }
  .root a {
    text-decoration: none;
    color: #007bff;
    margin-top: 10px; }
  .root .thirdPartyButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px; }
    .root .thirdPartyButtons button {
      margin-top: 8px; }
  .root .portalSelection {
    margin: 0px; }

/*# sourceMappingURL=signin.css.map */

.joinPortal {
  padding: 20px;
  max-width: 400px;
  margin: 0 auto; }
  .joinPortal h5 {
    margin-bottom: 20px; }
  .joinPortal .MuiFormControl-root {
    width: 100%;
    margin-bottom: 20px; }
  .joinPortal .MuiFormControlLabel-root {
    display: block; }
  .joinPortal .submitButton {
    margin-top: 20px; }

/*# sourceMappingURL=join-portal.css.map */

/* Define variables for responsive breakpoints */
.main-header {
  display: flex;
  flex-basis: content;
  flex-wrap: nowrap;
  flex-grow: inherit;
  justify-content: center;
  align-items: center;
  padding: .25 rem;
  background-color: #73b304;
  color: #fff;
  width: 100%;
  /* Responsive styles */ }
  .main-header .logo {
    flex: 1 1;
    font-size: 1.5rem;
    font-weight: bold;
    margin: auto .5rem; }
    .main-header .logo a {
      color: #fff;
      text-decoration: none; }
  .main-header .nav-links {
    display: flex;
    align-items: center; }
    .main-header .nav-links a {
      color: #fff;
      text-decoration: none;
      margin-right: 1rem; }
  .main-header .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer; }
    .main-header .hamburger .line {
      width: 30px;
      height: 3px;
      background-color: #fff;
      margin: 3px 0; }
  @media screen and (max-width: 768px) {
    .main-header .nav-links {
      display: none; }
    .main-header .hamburger {
      display: flex;
      margin: auto 1rem; }
    .main-header .hamburger.active {
      position: fixed;
      top: 0;
      left: 0;
      background-color: #9ce71a;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1000; }
    .main-header .hamburger.active .line:nth-child(1) {
      -webkit-transform: translateY(7px) rotate(45deg);
              transform: translateY(7px) rotate(45deg); }
    .main-header .hamburger.active .line:nth-child(2) {
      opacity: 0; }
    .main-header .hamburger.active .line:nth-child(3) {
      -webkit-transform: translateY(-7px) rotate(-45deg);
              transform: translateY(-7px) rotate(-45deg); }
    .main-header .hamburger.active .line {
      transition: background-color 0.2s, -webkit-transform 0.2s;
      transition: background-color 0.2s, transform 0.2s;
      transition: background-color 0.2s, transform 0.2s, -webkit-transform 0.2s; }
    .main-header .hamburger.active + .nav-links {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      background-color: #9ce71a;
      padding: 1rem;
      z-index: 999; }
    .main-header .hamburger.active + .nav-links a {
      display: block;
      margin: 0.5rem 0; } }

/*# sourceMappingURL=header.css.map */

.price-submit-button {
  justify-content: center;
  align-items: center; }

.price-footer {
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .price-footer .price-tier {
    margin: auto 3.5rem; }

/*# sourceMappingURL=braintree.css.map */

._1vq59Oc5wh62y3nTPpsnuO {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70%; }

._1FSLVVxjOxlmcOfdzNaPlp {
  margin-top: 10px; }

.yxeA_vt3E1oqQtqm8zTOx {
  height: 10px;
  width: 100%; }

._1sgsfxCZjZCJe_5vbJZZh6 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  justify-content: center;
  align-content: center; }

.KZ0W6qLhZ2a0Q6gGczB32, #plan {
  display: flex;
  justify-content: center;
  align-items: center; }
  .KZ0W6qLhZ2a0Q6gGczB32 button, #plan button {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    text-align: center;
    margin: auto;
    border: none;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.15); }
    .KZ0W6qLhZ2a0Q6gGczB32 button p, #plan button p {
      font-size: 10px; }
    .KZ0W6qLhZ2a0Q6gGczB32 button:hover, #plan button:hover {
      background: #f2f2f2;
      background: linear-gradient(135deg, #f2f2f2 0%, #cbcbcb 50%, white 100%); }
  .KZ0W6qLhZ2a0Q6gGczB32 img, #plan img {
    grid-area: image;
    justify-self: end;
    width: auto;
    height: 200px; }

/*# sourceMappingURL=TierMain.css.map */

._1v2HP5p5m7BSRCbfArCaHF {
  text-align: center; }
  ._1v2HP5p5m7BSRCbfArCaHF h2 {
    color: seagreen; }
  ._1v2HP5p5m7BSRCbfArCaHF a {
    display: block; }

._1SRLpYcGAmQxnfJ0Es0k79 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3%; }

._3Qy9EpTwWS5TFOXIlMOZXj {
  margin: auto 35px; }
  ._3Qy9EpTwWS5TFOXIlMOZXj p {
    text-align: center; }
  ._3Qy9EpTwWS5TFOXIlMOZXj label {
    min-width: 250px;
    margin: 1px 10px;
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em; }
  ._3Qy9EpTwWS5TFOXIlMOZXj input,
  ._3Qy9EpTwWS5TFOXIlMOZXj .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white; }
    ._3Qy9EpTwWS5TFOXIlMOZXj input::-webkit-input-placeholder, ._3Qy9EpTwWS5TFOXIlMOZXj .StripeElement::-webkit-input-placeholder {
      color: #aab7c4; }
    ._3Qy9EpTwWS5TFOXIlMOZXj input::placeholder,
    ._3Qy9EpTwWS5TFOXIlMOZXj .StripeElement::placeholder {
      color: #aab7c4; }
    ._3Qy9EpTwWS5TFOXIlMOZXj input:focus,
    ._3Qy9EpTwWS5TFOXIlMOZXj input .StripeElement--focus,
    ._3Qy9EpTwWS5TFOXIlMOZXj .StripeElement:focus,
    ._3Qy9EpTwWS5TFOXIlMOZXj .StripeElement .StripeElement--focus {
      box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
      transition: all 150ms ease; }
  ._3Qy9EpTwWS5TFOXIlMOZXj a {
    display: block;
    text-align: center;
    margin-top: 1em; }

.LArItxC4yjWTJG8P5g7T0 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-decoration: none;
  margin-top: 5%;
  background-color: #008cdd;
  border: 1px solid #008cdd;
  font-size: 1em;
  font-weight: bold;
  color: white;
  border-radius: 5px;
  padding: 0.5em 1em;
  width: 200px;
  transition: opacity 200ms;
  cursor: pointer; }
  .LArItxC4yjWTJG8P5g7T0.success {
    background-color: seagreen;
    border-color: seagreen; }
  .LArItxC4yjWTJG8P5g7T0:hover {
    opacity: 0.8; }
  .LArItxC4yjWTJG8P5g7T0:disabled {
    opacity: 0.5;
    cursor: not-allowed; }

._3846tld2s8nMrzgJaSxQpG {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 25px; }
  ._3846tld2s8nMrzgJaSxQpG .EGqmgyZyN2i5sbdgwAieA {
    height: auto; }
  ._3846tld2s8nMrzgJaSxQpG ._3izbjW-PHqFz9ZE2JwnmlL {
    margin: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    ._3846tld2s8nMrzgJaSxQpG ._3izbjW-PHqFz9ZE2JwnmlL ._2Rhr9dbnrxkPjBAbcaqJ5o {
      max-width: 100px;
      background-color: #dd000b;
      margin: auto 10px; }
    ._3846tld2s8nMrzgJaSxQpG ._3izbjW-PHqFz9ZE2JwnmlL ._2pBH_m2uPzqnhkXamKi5gy {
      max-width: 100px;
      margin: auto 10px; }
  ._3846tld2s8nMrzgJaSxQpG ._28Ir6WwKTJ81IFIQujgPEv * {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    margin: auto; }
    ._3846tld2s8nMrzgJaSxQpG ._28Ir6WwKTJ81IFIQujgPEv * Input {
      width: 25%; }

/*# sourceMappingURL=StripeMain.css.map */

._2XZi4cwHfhgKdUH41l3CrY {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70%; }

.C0meewJe9Ot0HDgF5Lda2 {
  margin-top: 10px; }

._3FTsPCXOSVYZPzo4qzC6Kq {
  width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; }
  ._3FTsPCXOSVYZPzo4qzC6Kq > .button {
    width: 100%; }

/*# sourceMappingURL=CheckoutMain.css.map */

.listing-form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem; }
  .listing-form-container h2 {
    font-size: 24px;
    margin-bottom: 1.5rem;
    text-align: center; }
  .listing-form-container .listing-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem; }
    .listing-form-container .listing-form .time-form {
      display: flex;
      flex-direction: row;
      flex-flow: row;
      margin-bottom: 1rem; }
    .listing-form-container .listing-form .form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem; }
      .listing-form-container .listing-form .form-group label {
        font-weight: bold;
        margin-bottom: 0.5rem; }
      .listing-form-container .listing-form .form-group input[type='text'],
      .listing-form-container .listing-form .form-group input[type='number'],
      .listing-form-container .listing-form .form-group select,
      .listing-form-container .listing-form .form-group textarea {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px; }
      .listing-form-container .listing-form .form-group textarea {
        height: 100px; }
      .listing-form-container .listing-form .form-group select {
        -webkit-appearance: none;
                appearance: none;
        background-size: 16px;
        padding-right: 2rem; }
      .listing-form-container .listing-form .form-group .time-duration {
        width: 25%; }
      .listing-form-container .listing-form .form-group .category {
        width: 50%; }
      .listing-form-container .listing-form .form-group input[type='checkbox'] {
        margin-right: 0.5rem; }
    .listing-form-container .listing-form button {
      grid-column: span 2;
      background-color: #007bff;
      color: #fff;
      border: none;
      padding: 1rem;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s; }
      .listing-form-container .listing-form button:hover {
        background-color: #0056b3; }
  @media screen and (max-width: 600px) {
    .listing-form-container .listing-form {
      grid-template-columns: 1fr; }
      .listing-form-container .listing-form .form-group {
        margin-bottom: 1rem; }
      .listing-form-container .listing-form button {
        grid-column: 1; } }

/*# sourceMappingURL=listing-form.css.map */

._3_iWPQlRZFocKjBhWthcz9 .summary {
  justify-content: space-evenly;
  align-items: center;
  margin: auto 1em;
  border-right: 1px solid #dfe1e2; }
  @media screen and (max-width: 768px) {
    ._3_iWPQlRZFocKjBhWthcz9 .summary {
      align-items: center;
      justify-content: flex-start;
      border-right: none;
      margin: 0; } }

._3ji_MKa5aV-U2q_WXD-4Ho {
  display: flex;
  justify-content: center;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content; }

._1DcjMVZymlG0IJt0o8XKSF {
  margin: auto;
  width: 95%; }

._3escad7BbqqJHbzWqyfzl7 {
  width: 1px; }
  ._3escad7BbqqJHbzWqyfzl7 > .button {
    background-color: #a0a9b0;
    min-width: inherit;
    padding: 12px; }

/* Define any global styles or variables here */
/* Style for the main container */
/* Style for the action box */
.action-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  /* Adjust the color as needed */
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 2rem; }
  @media screen and (max-width: 768px) {
    .action-box {
      display: flex;
      overflow-x: scroll;
      justify-content: space-evenly;
      align-items: center;
      background-color: #f7f7f7;
      /* Adjust the color as needed */
      border-radius: 4px;
      padding: 0;
      margin-bottom: 5%; } }

/* Style for the search bar */
.search-bar {
  flex: 1 1;
  margin-right: 1rem; }
  .search-bar .MuiInputBase-input {
    font-size: 1rem;
    color: #333;
    /* Adjust the color as needed */ }

/* Style for the action button */
.action-button {
  background-color: #007bff;
  /* Adjust the color as needed */
  color: #ffffff;
  /* Adjust the color as needed */
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease; }
  .action-button:hover {
    background-color: #0056b3;
    /* Adjust the color as needed */ }

/* Style for the table */
.table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px; }
  .table thead {
    background-color: #f7f7f7;
    /* Adjust the color as needed */
    font-weight: bold; }
    .table thead th {
      padding: 1rem;
      text-align: left; }
  .table tbody tr {
    transition: background-color 0.3s ease; }
    .table tbody tr:hover {
      background-color: #f7f7f7;
      /* Adjust the color as needed */ }
    .table tbody tr td {
      padding: 1rem; }
      .table tbody tr td.bold {
        font-weight: bold; }
      .table tbody tr td .selector {
        /* Add any specific styles for the selector component here */ }
      .table tbody tr td .details-cell {
        text-align: center; }
      .table tbody tr td button {
        background-color: #ffffff;
        color: #007bff;
        /* Adjust the color as needed */
        border: 1px solid #007bff;
        /* Adjust the color as needed */
        padding: 0.75rem 1.5rem;
        border-radius: 4px;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease; }
        .table tbody tr td button:hover {
          background-color: #007bff;
          /* Adjust the color as needed */
          color: #ffffff;
          /* Adjust the color as needed */ }

/*# sourceMappingURL=job-table.css.map */

.ueQCUdYs3RsjPo1XieRjl > h2 {
  display: flex;
  align-items: baseline; }
  .ueQCUdYs3RsjPo1XieRjl > h2 > .group {
    font-size: 18px;
    font-weight: lighter;
    padding: 0px 10px;
    color: #aaa; }

.IaJ1niXCAtFcsbANvNPuj {
  padding: 0px; }
  .IaJ1niXCAtFcsbANvNPuj > .switch-buffer {
    height: 60px; }
  .IaJ1niXCAtFcsbANvNPuj > .switch-table > .main {
    border: none; }

._2TgMJShNidgd9aX0e-77t2 {
  padding: 0px 10px; }
  ._2TgMJShNidgd9aX0e-77t2 .table {
    width: 100%; }

._25TNURaXn1lIKVhq7NuXVN {
  padding: 10px; }
  ._25TNURaXn1lIKVhq7NuXVN .span, ._25TNURaXn1lIKVhq7NuXVN .input {
    display: block;
    max-width: 600px;
    padding: 8px;
    line-height: 30px; }
  ._25TNURaXn1lIKVhq7NuXVN > .button {
    margin-bottom: 20px; }
  ._25TNURaXn1lIKVhq7NuXVN > .notes {
    border-top: 1px solid #dfe1e2;
    border-bottom: 1px solid #dfe1e2; }

.TjaiI8xxpZ4jch_YUPr-c {
  padding: 20px 10px;
  display: flex; }
  .TjaiI8xxpZ4jch_YUPr-c > form {
    flex: 1 1;
    margin: 0px 10px; }

._2MIwk--zZWbkMt-zpu2SJr {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 10px; }
  ._2MIwk--zZWbkMt-zpu2SJr > div {
    min-height: 36px;
    display: flex;
    align-items: center; }
    ._2MIwk--zZWbkMt-zpu2SJr > div:not(:last-child) {
      margin-bottom: 5px; }
    ._2MIwk--zZWbkMt-zpu2SJr > div input, ._2MIwk--zZWbkMt-zpu2SJr > div span, ._2MIwk--zZWbkMt-zpu2SJr > div button {
      margin-left: 5px; }
    ._2MIwk--zZWbkMt-zpu2SJr > div.transfer > div {
      margin-right: 10px; }

/*# sourceMappingURL=job-view.css.map */

._1ZxzOh0oc4C0SlujIN2Yzq > h2 {
  display: flex;
  align-items: baseline; }
  ._1ZxzOh0oc4C0SlujIN2Yzq > h2 > .group {
    font-size: 18px;
    font-weight: lighter;
    padding: 0px 10px;
    color: #aaa; }

._1XVnKKOvNqcD909kIZ8IbS {
  padding: 0px; }
  ._1XVnKKOvNqcD909kIZ8IbS > .switch-buffer {
    height: 60px; }
  ._1XVnKKOvNqcD909kIZ8IbS > .switch-table > .main {
    border: none; }

.qaXYjA1hPw1eGsYl0ULYa {
  padding: 0px 10px; }
  .qaXYjA1hPw1eGsYl0ULYa .table {
    width: 100%; }

._15_kSXEiKAnI9PYM19C3hC {
  padding: 10px; }
  ._15_kSXEiKAnI9PYM19C3hC .span, ._15_kSXEiKAnI9PYM19C3hC .input {
    display: block;
    max-width: 600px;
    padding: 8px;
    line-height: 30px; }
  ._15_kSXEiKAnI9PYM19C3hC > .button {
    margin-bottom: 20px; }
  ._15_kSXEiKAnI9PYM19C3hC > .notes {
    border-top: 1px solid #dfe1e2;
    border-bottom: 1px solid #dfe1e2; }

.ghn2snubGBJxT9JahLIWQ {
  padding: 20px 10px;
  display: flex; }
  .ghn2snubGBJxT9JahLIWQ > form {
    flex: 1 1;
    margin: 0px 10px; }

._1I1lYXwxmGlZnKayaYkm1A {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 10px; }
  ._1I1lYXwxmGlZnKayaYkm1A > div {
    min-height: 36px;
    display: flex;
    align-items: center; }
    ._1I1lYXwxmGlZnKayaYkm1A > div:not(:last-child) {
      margin-bottom: 5px; }
    ._1I1lYXwxmGlZnKayaYkm1A > div input, ._1I1lYXwxmGlZnKayaYkm1A > div span, ._1I1lYXwxmGlZnKayaYkm1A > div button {
      margin-left: 5px; }
    ._1I1lYXwxmGlZnKayaYkm1A > div.transfer > div {
      margin-right: 10px; }

/*# sourceMappingURL=jobs-view.css.map */



/*# sourceMappingURL=ProductsPage.css.map */

